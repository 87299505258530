//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import exportCsvMixin from '~/components/mixins/exportCsv';
import constant from '~/const';
import TradingOrdersFilters from './TradingOrdersFilters.vue';
import TradingOrdersExtra from './TradingOrdersExtra.vue';
import Pagination from '~/components/Pagination.vue';
import Table from '~/components/ui/Table.vue';
import tableConfig from './tradingOrdersTableConfig';
import actionsConfig from './tradingOrdersActionsConfig';
import User from '~/components/User.vue';
import GetReport from '~/components/GetReport.vue';

export default {
  components: {
    TradingOrdersFilters,
    TradingOrdersExtra,
    Pagination,
    Table,
    User,
    GetReport,
  },

  mixins: [exportCsvMixin],

  data() {
    return {
      selection: [],
      isSelectedAll: false,
      loader: false,
      tableConfig,
      actionsConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapState('userTradingOrders', [
      'tradingOrders',
      'page',
      'pages',
      'sort',
    ]),
    ...mapGetters('userTradingOrders', ['requestData']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('userTradingOrders', {
      getTradingOrdersAction: 'getTradingOrders',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      cancelOrderListAction: 'cancelOrderList',
    }),

    async getTradingOrders(data = {}) {
      this.loader = true;
      await this.getTradingOrdersAction(data);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }

      this.getTradingOrders();
    },

    applySort(data) {
      this.setSortAction(data);
      this.getTradingOrders();
    },

    setPage(value) {
      this.setPageAction(value);
      const extendedRequestData = {
        getTotal: false,
      };
      this.getTradingOrders(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    hasTrades(orderStatus) {
      const hasTradesStatusList = [
        constant.orders.ORDER_STATUS.PARTIALLY_FILLED,
        constant.orders.ORDER_STATUS.FILLED,
        constant.orders.ORDER_STATUS.CANCELLED,
      ];

      return hasTradesStatusList.includes(orderStatus);
    },

    selectAll() {
      this.isSelectedAll = true;
    },

    selectEntirePage() {
      const { tradingOrders } = this;
      this.selection = tradingOrders.map((e) => e.id);
    },

    cancelOrders() {
      const { isSelectedAll, selection } = this;
      if (isSelectedAll) {
        this.setErrorNotification('You cannot cancel all orders');
        return;
      }

      if (!selection.length) {
        this.setErrorNotification('Select orders');
        return;
      }

      this.confirmAction({
        text: 'Are you sure you want to cancel the selected orders?',
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            await this.cancelOrderListAction(this.selection);
            await this.getTradingOrdersAction();
            this.setSuccessNotification('Selected orders successfully canceled');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    exportCsv() {
      const { isSelectedAll, selection, requestData } = this;
      this.$exportCsv({
        isSelectedAll,
        selection,
        requestData,
        selectionKey: 'idList',
        type: constant.reports.REPORT_TYPE.ORDERS,
      });
    },
  },
};
