export default [
  {
    caption: 'Select all',
    action: 'selectAll',
  },
  {
    caption: 'Select entire page',
    action: 'selectEntirePage',
  },
  {
    caption: 'Actions',
    action: null,
    children: [
      {
        caption: 'Export CSV',
        action: 'exportCsv',
      },
    ],
  },
];
