//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';
import Filters from '~/components/filters/Index.vue';
import TextField from '~/components/ui/TextField.vue';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';
import InputRange from '~/components/ui/InputRange.vue';

export default {

  components: {
    Filters,
    TextField,
    Select,
    DateRange,
    InputRange,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        marketIdList: [],
        side: null,
        orderTypeList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        orderStatusList: [],
        orderQtyFrom: '',
        orderQtyTo: '',
        priceFrom: '',
        priceTo: '',
        filledFrom: '',
        filledTo: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('currencies', ['markets']),
    ...mapState('userTradingOrders', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userTradingOrders', ['setFilters']),
  },
};
