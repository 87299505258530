//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import exportCsvMixin from '~/components/mixins/exportCsv';
import constant from '~/const';
import Filters from './TradingTradesFilters.vue';
import Pagination from '~/components/Pagination.vue';
import User from '~/components/User.vue';
import TradingTradesExtra from './TradingTradesExtra.vue';
import GetReport from '~/components/GetReport.vue';
import tableConfig from './tradingTradesTableConfig';
import actionsConfig from './tradingTradesActionsConfig';

export default {
  components: {
    Filters,
    Pagination,
    User,
    TradingTradesExtra,
    GetReport,
  },

  mixins: [exportCsvMixin],

  data() {
    return {
      tableConfig,
      actionsConfig,
      constant,
      isSelectedAll: false,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userTradingTrades', ['page', 'pages', 'data', 'sort']),
    ...mapGetters('userTradingTrades', ['requestData']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification']),
    ...mapActions('userTradingTrades', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.loader = true;
      await this.loadDataAction(data);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    selectAll() {
      this.isSelectedAll = true;
    },

    selectEntirePage() {
      const { data } = this;
      this.selection = data.map((e) => e.id);
    },

    exportCsv() {
      const { isSelectedAll, selection, requestData } = this;
      this.$exportCsv({
        isSelectedAll,
        selection,
        requestData,
        selectionKey: 'idList',
        type: constant.reports.REPORT_TYPE.TRADES,
      });
    },
  },
};
