//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import User from '~/components/User.vue';
import tableConfig from './tradingTradesInnerTableConfig';
import constant from '~/const';

export default {

  components: { User },
  props: {
    tradeId: { type: Number, required: true },
  },

  data() {
    return {
      loader: true,
      tableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('userTradingTrades', ['innerOrders']),

    currentInnerOrdersList() {
      const { innerOrders, tradeId } = this;
      const current = innerOrders.find((e) => e.tradeId === tradeId);
      if (current) {
        return current.orderList;
      }
      return [];
    },
  },

  async mounted() {
    this.setGeneralProgress(true);
    await this.loadInnerOrders({
      tradeIdList: [this.tradeId],
    });
    this.loader = false;
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('userTradingTrades', ['loadInnerOrders']),
    ...mapActions('common', ['setGeneralProgress']),
  },
};
