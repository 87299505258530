//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import UserTradingOrders from '~/views/UserTrading/UserTradingOrders/TradingOrders.vue';
import UserTradingTrades from '~/views/UserTrading/UserTradingTrades/TradingTrades.vue';

export default {
  components: {
    UserTradingOrders,
    UserTradingTrades,
  },

  computed: {
    ...mapState('currencies', ['markets']),
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.getTradingOrders(),
      this.getTradingTrades(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('userTradingOrders', {
      getTradingOrders: 'getTradingOrders',
    }),
    ...mapActions('userTradingTrades', {
      getTradingTrades: 'loadData',
    }),
  },
};
