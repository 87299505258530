//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        marketIdList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        amountFrom: '',
        amountTo: '',
        priceFrom: '',
        priceTo: '',
      },
    };
  },

  computed: {
    ...mapState('currencies', ['markets']),
    ...mapState('userTradingTrades', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userTradingTrades', ['setFilters']),
  },
};
