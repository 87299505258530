var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading-orders-extra"},[_c('UiTable',{attrs:{"data":_vm.currentInnerTradeList,"config":_vm.tableConfig,"is-loading":_vm.loader,"per-page":1,"class-name":"pt-4 pb-4"},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])])]}},{key:"taker",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.takerId,"username":row.takerName}})]}},{key:"maker",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.makerId,"username":row.makerName}})]}},{key:"price",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.price)+" ")]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [(row.makerId === row.sellerId)?_c('span',{staticClass:"success--text"},[_vm._v("+"+_vm._s(row.amount))]):_c('span',{staticClass:"error--text"},[_vm._v("-"+_vm._s(row.amount))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }