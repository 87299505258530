var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading-trades"},[_c('div',{staticClass:"trading-trades__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.TRADES,"command":_vm.requestData}})],1),_c('div',{staticClass:"trading-trades__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"is-selected-all":_vm.isSelectedAll,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-select":"","show-actions":"","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event},"update:isSelectedAll":function($event){_vm.isSelectedAll=$event},"update:is-selected-all":function($event){_vm.isSelectedAll=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('TradingTradesExtra',{attrs:{"trade-id":row.id}})]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])])]}},{key:"taker",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.takerId,"username":row.takerName}})]}},{key:"maker",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.makerId,"username":row.makerName}})]}},{key:"price",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.price)+" ")]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [(row.makerId === row.sellerId)?_c('span',{staticClass:"success--text"},[_vm._v("+"+_vm._s(row.amount))]):_c('span',{staticClass:"error--text"},[_vm._v("-"+_vm._s(row.amount))])]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }